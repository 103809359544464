import { formatDistanceToNow, isValid, subSeconds } from 'date-fns';
import { lt } from 'date-fns/locale';
import store from '@/store';

export function getTimeFromDate(date) {
  const d = new Date(date);
  if (!date || !isValid(d)) {
    return '';
  }
  const options = { addSuffix: true };
  if (store.state.settings.locale === 'lt') {
    options.locale = lt;
  }
  return formatDistanceToNow(subSeconds(new Date(date), 10), options);
}
