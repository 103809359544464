<template>
  <v-form @submit.prevent>
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div>
          {{ formTitle }}
        </div>
        <div class="text-subtitle-2 grey--text">
          <template v-if="item.created_at">
            {{ $t('general.date_and_time.created_at_male') }} {{ getTimeFromDate(item.created_at) }}
          </template>
        </div>
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <FormAttachments
            :attachments="item.attachments"
            :can-delete="canEdit"
            @deleteAttachment="$emit('delete:attachment', $event.file)"
          />

          <template
            v-if="
              currentUserPermissionInSelectedProject === 'admin' ||
              ($isEmployee() && $can(['projects.*']))
            "
          >
            <v-col cols="12" :sm="item.sprint_id ? 12 : 6">
              <v-select
                v-model="item.sprint_id"
                :items="projectSprints"
                :error-messages="errors.sprint_id"
                :label="$t('projects.labels.assigned_to_sprint')"
                item-text="sprint_name"
                item-value="id"
                clearable
                @change="formMixin_clearErrors('sprint_id')"
              />
            </v-col>

            <v-col v-if="!item.sprint_id" cols="12" sm="6">
              <v-select
                v-model="item.status"
                :items="projectUserStoryStatuses"
                :error-messages="errors.status"
                :label="$t('general.status')"
                @change="formMixin_clearErrors('status')"
              />
            </v-col>
          </template>

          <v-col cols="12">
            <v-textarea
              v-model="item.title"
              :error-messages="errors.title"
              :label="formMixin_getRequiredFieldLabel($t('general.title'))"
              rows="1"
              auto-grow
              @input="formMixin_clearErrors('title')"
            />
          </v-col>

          <v-col cols="12">
            <ProjectUserStoryDescriptionTextarea
              v-model="item.info"
              :error-messages="errors.info"
              :can-edit="canEdit"
              :project-user-story="projectUserStory"
              @input="formMixin_clearErrors('info')"
            />
          </v-col>

          <v-col v-if="item.id" class="my-4" cols="12">
            <BaseComments
              :model-id="item.id"
              class="mt-2"
              model-type="project_user_stories"
              @create="updateCommentCount(item.comments_count + 1)"
              @delete="updateCommentCount(item.comments_count - 1)"
            />
          </v-col>

          <v-col
            v-if="
              item.id &&
              ((currentUserPermissionInSelectedProject !== 'client' && !$isClient()) ||
                ($isClient() && currentUserPermissionInSelectedProject === 'supplier'))
            "
            class="my-3"
            cols="12"
          >
            <ProjectTasks
              v-if="selectedProject && selectedProject.id"
              :user-story="item"
              :project-id="selectedProject.id"
            />
          </v-col>

          <v-col v-if="canEdit" cols="12">
            <v-file-input v-model="newAttachments" :label="$t('general.add_file')" multiple />
          </v-col>

          <template
            v-if="
              currentUserPermissionInSelectedProject === 'admin' ||
              ($isEmployee() && $can(['projects.*']))
            "
          >
            <v-col cols="12" sm="4">
              <ProductAutocomplete
                v-model="item.product_id"
                :error-messages="errors.product_id"
                @input="formMixin_clearErrors('product_id')"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                v-model="item.quantity"
                :label="$t('general.quantity')"
                :error-messages="errors.quantity"
                type="number"
                @input="formMixin_clearErrors('quantity')"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-checkbox
                v-model="item.is_canceled"
                :error-messages="errors.is_canceled"
                :label="$t('general.canceled')"
                @change="formMixin_clearErrors('is_canceled')"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <ContactAutocomplete
                v-model="item.supplier_contact_id"
                :error-messages="errors.supplier_contact_id"
                :label="$t('general.supplier_contact')"
                suppliers-only
                @input="formMixin_clearErrors('supplier_contact_id')"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                v-model="item.supplier_status"
                :error-messages="errors.supplier_status"
                :label="$t('general.supplier_status')"
                :items="supplierStatuses"
                @input="formMixin_clearErrors('supplier_status')"
              />
            </v-col>
          </template>

          <v-col
            v-if="canEdit && currentUserPermissionInSelectedProject !== 'client' && !$isClient()"
            cols="12"
          >
            <v-row v-for="points in projectUserStoryPointTypes" :key="points.type" dense>
              <v-col cols="12" sm="4" class="v-label d-flex align-center">
                {{ $t(`projects.general.${points.type}_points`) }}
              </v-col>

              <v-col cols="12" sm="4" class="d-flex">
                <!--                <v-text-field-->
                <!--                  v-model="item[points.estimatedPointsFieldKey]"-->
                <!--                  class="mr-2"-->
                <!--                  type="number"-->
                <!--                />-->
                <v-select
                  v-model="item[points.estimatedPointsFieldKey]"
                  class="mr-2"
                  :items="estimatedPointsItems"
                />

                <v-select v-model="selectedPointUnit" :items="pointUnitOptions" />
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  :value="+item[points.completedPointsFieldKey]"
                  :error-messages="errors[points.completedPointsFieldKey]"
                  :items="assignedEmployees"
                  :label="$t('projects.general.assigned_employee')"
                  item-value="user_id"
                  item-text="personal_data.full_name"
                  clearable
                  hide-details
                  @change="
                    $set(item, points.completedPointsFieldKey, $event);
                    formMixin_clearErrors(points.completedPointsFieldKey);
                  "
                />
              </v-col>

              <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12">
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-3" dense>
          <v-col v-if="showPrice" cols="12" sm="6" md="4">
            <v-text-field
              v-model="item.price"
              :error-messages="errors.price"
              :label="$t('general.price')"
              :disabled="isPriceDisabled"
              type="number"
              @input="formMixin_clearErrors('price')"
            />
          </v-col>

          <template
            v-if="
              currentUserPermissionInSelectedProject === 'admin' ||
              ($isEmployee() && $can(['projects.*']))
            "
          >
            <v-col cols="12" sm="6" md="4">
              <BaseDatepickerInput
                v-model="item.due_date"
                :error-messages="errors.due_date"
                :label="$t('projects.labels.deadline')"
                @input="formMixin_clearErrors('due_date')"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-checkbox
                v-model="item.is_urgent"
                :error-messages="errors.is_urgent"
                :label="$t('projects.labels.is_urgent')"
                @change="formMixin_clearErrors('is_urgent')"
              />
            </v-col>
          </template>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="canEdit">
        <span v-if="!item.id" class="text-subtitle-2 ml-3">
          * {{ $t('general.must_be_filled') }}
        </span>
        <v-btn
          v-else
          :disabled="loading[`delete:api/project-user-stories/${item.id}`]"
          :loading="loading[`delete:api/project-user-stories/${item.id}`]"
          color="primary"
          text
          @click.native="$emit('delete', item)"
        >
          {{ $t('general.controls.delete') }}
        </v-btn>

        <v-spacer />

        <v-btn text @click="$emit('cancel', item)">
          {{ $t('general.controls.cancel') }}
        </v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text @click="onSubmit">
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>

      <v-card-actions v-else>
        <v-spacer />
        <v-btn text @click="$emit('cancel', item)">
          {{ $t('general.controls.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import { getTimeFromDate } from '@/util/time';
import FormAttachments from '@/components/FormAttachments';
import { mapGetters, mapState } from 'vuex';
import ProjectUserStoryDescriptionTextarea from '@/components/form-fields/ProjectUserStoryDescriptionTextarea';
import BaseComments from '@/components/base/BaseComments';
import ProjectTasks from '@/views/projects/ProjectTasks';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import ProductAutocomplete from '@/components/autocompletes/ProductAutocomplete';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete';

export default {
  name: 'ProjectUserStoryForm',

  components: {
    ContactAutocomplete,
    ProductAutocomplete,
    BaseDatepickerInput,
    ProjectTasks,
    BaseComments,
    ProjectUserStoryDescriptionTextarea,
    FormAttachments,
  },

  mixins: [formMixin],

  props: {
    projectUserStory: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    convertingIssue: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      item: {},
      newAttachments: [],
      selectedPointUnit: 'hours', // TODO connect to backend
    };
  },

  computed: {
    ...mapState('projectSprints', ['projectSprints']),
    ...mapState('projects', ['selectedProject']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
    ...mapGetters('projectUserStories', [
      'projectUserStoryStatuses',
      'projectUserStoryPointTypes',
      'supplierStatuses',
    ]),
    ...mapGetters(['loading']),

    estimatedPointsItems() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    },

    assignedEmployees() {
      return (
        this.selectedProject.assignees?.filter((assignee) => assignee.permission !== 'client') || []
      );
    },

    canEdit() {
      if (this.item.id && this.$isClient()) {
        return false;
      }
      if (!this.item.id) {
        return true;
      }
      if (
        this.currentUserPermissionInSelectedProject === 'admin' ||
        (this.$isEmployee() && this.$can(['projects.*']))
      ) {
        return true;
      }
      return this.item.created_by === this.currentUser.id && !this.item.sprint_id;
    },

    formTitle() {
      if (!this.item.id) {
        return this.$t('projects.modal_titles.new_story');
      }
      if (this.canEdit) {
        return this.$t('projects.modal_titles.edit_story');
      }
      return this.$t('projects.modal_titles.preview_story');
    },

    showPrice() {
      if (
        this.currentUserPermissionInSelectedProject === 'admin' ||
        (this.$isEmployee() && this.$can(['projects.*']))
      ) {
        return true;
      }
      return this.currentUserPermissionInSelectedProject === 'client' && this.item.id;
    },

    isPriceDisabled() {
      if (
        this.currentUserPermissionInSelectedProject === 'admin' ||
        (this.$isEmployee() && this.$can(['projects.*']))
      ) {
        return false;
      }
      return true;
    },

    pointUnitOptions() {
      return [
        { text: this.$t('general.minutes').toLowerCase(), value: 'minutes' },
        { text: this.$t('general.hours').toLowerCase(), value: 'hours' },
        { text: this.$t('general.days').toLowerCase(), value: 'days' },
      ];
    },
  },

  created() {
    this.item = clone(this.projectUserStory);
  },

  methods: {
    getTimeFromDate,

    updateCommentCount(newCount) {
      this.$emit('update:comment-count', newCount);
    },

    onSubmit() {
      this.$emit('save', this.item);
      if (this.newAttachments.length) {
        this.$emit('upload:attachments', this.newAttachments);
      }
    },
  },
};
</script>
