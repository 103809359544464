<template>
  <span>
    <v-btn
      v-if="attachments.length === 1"
      icon
      small
      @click.stop="onAttachmentClick(attachments[0])"
    >
      <v-icon small>attach_file</v-icon>
    </v-btn>
    <v-menu
      v-else-if="attachments.length > 1"
      v-model="isMenuOpen"
      :close-on-content-click="false"
      bottom
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="ma-0" small icon @click.stop>
          <v-icon small>attach_file</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="attachment in attachments"
          :key="attachment.id"
          :disabled="downloadInProgressIds[attachment.id]"
          @click="onAttachmentClick(attachment)"
        >
          <v-list-item-icon>
            <v-progress-circular
              v-if="downloadInProgressIds[attachment.id]"
              size="24"
              width="3"
              indeterminate
            />
            <v-icon v-else>open_in_new</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{
              attachment.file_name
                ? attachment.file_name
                : this.$t('projects.labels.file_without_name')
            }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
import { openFile } from '../util/event-bus';

export default {
  name: 'ListAttachments',

  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isMenuOpen: false,
      downloadInProgressIds: {},
    };
  },

  methods: {
    async onAttachmentClick(file) {
      this.$set(this.downloadInProgressIds, file.id, true);
      await openFile({
        name: file.file_name,
        url: file.url,
      });
      this.$delete(this.downloadInProgressIds, file.id);
      this.isMenuOpen = false;
    },
  },
};
</script>
