<template>
  <div>
    <div v-if="!editing && projectUserStory.id">
      <h3
        :class="{ 'error--text': errorMessages && errorMessages.length }"
        class="text-h6 d-flex align-center"
      >
        {{ $t('general.description') }}
        <v-btn v-if="canEdit" class="ml-1" icon @click="editDescription">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-tooltip v-else bottom>
          <template #activator="{ on }">
            <v-btn v-on="on" class="ml-1" icon @click="appendDescription">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <div>
            {{ $t('projects.general.append_to_description') }}
          </div>
        </v-tooltip>
      </h3>
      <p
        v-if="value"
        v-html="descriptionHtml"
        :class="{ clickable: canEdit }"
        class="preserve-whitespace style-links"
        @click="editDescription"
      />
      <p v-else @click="editDescription">
        <em>{{ $t('projects.general.no_description').toLowerCase() }}</em>
      </p>
      <BaseFormErrors :error-messages="errorMessages" />
    </div>

    <v-textarea
      v-else
      :value="value"
      :error-messages="errorMessages"
      :label="$t('general.description')"
      ref="descriptionInput"
      rows="1"
      auto-grow
      @input="$emit('input', $event)"
      @blur="editing = false"
    />

    <AppendProjectUserStoryDescription
      v-if="$route.query['appending-description']"
      @update:description="$emit('input', $event)"
    />
  </div>
</template>

<script>
import linkifyStr from 'linkifyjs/string';
import BaseFormErrors from '@/components/base/BaseFormErrors';
import AppendProjectUserStoryDescription from '@/views/project-user-stories/AppendProjectUserStoryDescription';

export default {
  name: 'ProjectUserStoryDescriptionTextarea',

  components: { AppendProjectUserStoryDescription, BaseFormErrors },

  props: {
    value: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    projectUserStory: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      editing: false,
    };
  },

  computed: {
    descriptionHtml() {
      return linkifyStr(this.value || '');
    },
  },

  methods: {
    async editDescription(event) {
      if (
        event.target.nodeName.toLowerCase() === 'a' ||
        window.getSelection().toString() ||
        !this.canEdit
      ) {
        // user clicked on a link
        return;
      }

      // otherwise user clicked on regular text, initiate editing
      this.editing = true;
      await this.$nextTick();
      this.$refs.descriptionInput.focus();
    },

    appendDescription() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          'appending-description': true,
        },
      });
    },
  },
};
</script>

<style scoped></style>
