<template>
  <v-col v-if="attachments && attachments.length > 0" cols="12">
    <v-subheader class="pa-0" style="height: 20px">
      {{ $t('projects.general.attached_files') }}:
    </v-subheader>
    <v-chip
      v-for="(file, index) of attachments"
      :key="file.id"
      :close="canDelete && !downloadInProgressIds[file.id]"
      :disabled="downloadInProgressIds[file.id]"
      class="clickable"
      style="margin: 1px"
      small
      @click="onAttachmentClick(file)"
      @click:close="confirmAttachmentDelete(file, index)"
    >
      {{ file.file_name }}
      <v-progress-circular
        v-if="downloadInProgressIds[file.id]"
        class="ml-1"
        width="2"
        size="16"
        indeterminate
      />
    </v-chip>
  </v-col>
</template>

<script>
import { openConfirmDialog, openFile } from '../util/event-bus';

export default {
  name: 'FormAttachments',

  props: {
    attachments: Array,
    canDelete: Boolean,
  },

  data() {
    return {
      downloadInProgressIds: {},
    };
  },

  methods: {
    async confirmAttachmentDelete(file, index) {
      const confirmed = await openConfirmDialog({
        title: this.$t('projects.confirmations.remove_file'),
      });
      if (!confirmed) {
        return;
      }

      this.$emit('deleteAttachment', {
        file,
        index,
      });
    },

    async onAttachmentClick(file) {
      this.$set(this.downloadInProgressIds, file.id, true);
      await openFile({
        name: file.file_name,
        url: file.url,
      });
      this.$delete(this.downloadInProgressIds, file.id);
    },
  },
};
</script>
