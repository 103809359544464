<template>
  <v-card>
    <v-card-title>
      {{ $t('projects.modal_titles.edit_task') }}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <FormAttachments
          :attachments="item.attachments"
          can-delete
          @deleteAttachment="deleteAttachment"
        />

        <v-col cols="12">
          <v-textarea
            v-model="item.title"
            :error-messages="errors.title"
            :label="$t('general.title')"
            rows="1"
            auto-grow
            @blur="clearErrors('title')"
          />
        </v-col>

        <!--        <v-col cols="12">-->
        <!--          <v-radio-group-->
        <!--            v-model="item.type"-->
        <!--            :error-messages="errors['type']"-->
        <!--            row-->
        <!--            @change="clearErrors('type')"-->
        <!--          >-->
        <!--            <v-radio-->
        <!--              v-for="type in taskTypes"-->
        <!--              :key="type.value"-->
        <!--              :label="type.text"-->
        <!--              :value="type.value"-->
        <!--            />-->
        <!--          </v-radio-group>-->
        <!--        </v-col>-->

        <v-col cols="12">
          <v-textarea
            v-model="item.description"
            :error-messages="errors.description"
            :label="$t('general.description')"
            rows="1"
            auto-grow
            @blur="clearErrors('description')"
          />
        </v-col>

        <v-col cols="12">
          <v-file-input
            v-model="attachedFiles"
            :label="$t('general.controls.attach_files')"
            multiple
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="item.is_private"
            :error-messages="errors.is_private"
            class="form-checkbox"
            :label="$t('projects.labels.private')"
            @blur="clearErrors('is_private')"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="item.id && canEditTask"
        :disabled="requestPending || deletingTask"
        :loading="deletingTask"
        color="primary"
        text
        @click.native="deleteTask"
      >
        {{ $t('general.controls.delete') }}
      </v-btn>
      <v-spacer />
      <v-btn text @click.native="$emit('close')">
        {{ $t('general.controls.cancel') }}
      </v-btn>
      <v-btn
        v-if="canEditTask"
        :disabled="requestPending || deletingTask"
        :loading="requestPending"
        color="primary"
        text
        @click="save(apiRoute)"
      >
        {{ $t('general.controls.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormAttachments from '../FormAttachments.vue';
import { mapGetters } from 'vuex';
import { openConfirmDialog } from '@/util/event-bus';
import httpClient from '@/api/http-client';
import { mapErrorsToInputs } from '@/util/forms';

export default {
  name: 'ProjectTaskForm',

  components: {
    FormAttachments,
  },

  props: {
    item: {
      item: Object,
      default: () => ({}),
    },
    canEditTask: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      apiRoute: 'api/project-tasks/',
      attachedFiles: [],
      errors: {},
      deletingTask: false,
      requestPending: false,
    };
  },

  computed: {
    ...mapGetters('projectUserStories', ['projectUserStoryPointTypes']),

    taskTypes() {
      return this.projectUserStoryPointTypes.map((points) => ({
        value: points.type,
        text: points.label,
      }));
    },
  },

  methods: {
    deleteAttachment(event) {
      httpClient.delete(`api/project-attachment/${event.file.id}`).then(() => {
        this.item.attachments.splice(event.index, 1);
        this.$emit('update', this.item);
      });
    },

    async deleteTask() {
      const confirmed = await openConfirmDialog({
        title: this.$t('projects.confirmations.remove_task'),
      });
      if (!confirmed) {
        return;
      }

      httpClient
        .delete(`api/project-tasks/${this.item.id}`)
        .then(() => {
          this.$emit('delete', this.item);
          this.$emit('close');
        })
        .finally(() => {
          this.deletingTask = false;
        });
    },

    save(apiRoute) {
      this.requestPending = true;
      if (!this.item.type) {
        this.item.type = null;
      }
      httpClient
        .put(`${apiRoute}${this.item.id}`, this.item)
        .then((response) => {
          this.uploadAttachments(response.data);
          this.$emit('update', response.data);
          this.$emit('close');
        })
        .catch((error) => {
          this.errors = mapErrorsToInputs(error);
        })
        .finally(() => {
          this.requestPending = false;
        });
    },

    clearErrors(key) {
      if (this.errors[key]) {
        delete this.errors[key];
      }
    },

    uploadAttachments(task) {
      if (!this.attachedFiles.length) {
        return;
      }

      const requestBody = new FormData();
      [...this.attachedFiles].forEach((file) => {
        requestBody.append('file[]', file, file.name);
      });
      requestBody.append('project_task_id', task.id);
      requestBody.append('project_id', task.project_id);
      httpClient
        .post('api/project-attachment', requestBody, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (!task.attachments) {
            this.$set(task, 'attachments', []);
          }
          response.data.forEach((file) => {
            task.attachments.push(file);
          });
        })
        .catch((error) => {
          this.errors = mapErrorsToInputs(error);
        });
    },
  },
};
</script>
