<template>
  <div v-if="errorMessages.length" class="v-messages theme--light error--text" role="alert">
    <div class="v-messages__wrapper">
      <div v-for="error in errorMessages" :key="error" class="v-messages__message">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFormErrors',

  props: {
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
