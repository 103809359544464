<template>
  <BaseNestableDialog max-width="700" scrollable persistent>
    <v-card>
      <v-card-title>
        {{ $t('projects.general.description_update') }}
      </v-card-title>

      <v-card-text>
        <v-textarea
          v-model="description"
          :error-messages="projectUserStoryValidationErrors.description"
          :label="$t('general.text')"
          auto-grow
          @blur="clearErrors('description')"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="goBack">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn
          :disabled="
            loading[
              `put:api/project-user-stories/${this.selectedProjectUserStory.id}/update-description`
            ]
          "
          :loading="
            loading[
              `put:api/project-user-stories/${this.selectedProjectUserStory.id}/update-description`
            ]
          "
          color="primary"
          text
          @click="onSave"
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </BaseNestableDialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import clone from 'just-clone';
import BaseNestableDialog from '@/components/base/BaseNestableDialog';

export default {
  name: 'AppendProjectUserStoryDescription',

  components: { BaseNestableDialog },

  data() {
    return {
      errors: {},
      description: '',
    };
  },

  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('projectUserStories', [
      'selectedProjectUserStory',
      'projectUserStoryValidationErrors',
    ]),
    ...mapGetters(['loading']),
  },

  methods: {
    ...mapActions('projectUserStories', ['updateProjectUserStoryDescription']),
    ...mapMutations('projectUserStories', [
      'CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS',
      'UPDATE_PROJECT_USER_STORY',
    ]),

    clearErrors(field) {
      if (this.projectUserStoryValidationErrors[field]) {
        this.CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS(field);
      }
    },

    onSave() {
      const userName = this.currentUser.personal_data?.full_name || this.currentUser.username;

      let newDescription;
      if (this.selectedProjectUserStory.info) {
        newDescription = `\n-\n${userName}:\n${this.description}`;
      } else {
        newDescription = `${userName}:\n${this.description}`;
      }

      this.updateProjectUserStoryDescription({
        projectUserStory: this.selectedProjectUserStory,
        description: newDescription,
      }).then((updatedUserStory) => {
        this.$emit('update:description', updatedUserStory.info);
        this.goBack();
      });
    },

    goBack() {
      const query = clone(this.$route.query);
      delete query['appending-description'];
      this.$router.push({
        ...this.$route,
        query,
      });
    },
  },
};
</script>
