<template>
  <div>
    <div class="d-flex align-center">
      <h3 class="text-h6">{{ $t('general.tasks') }}</h3>
    </div>
    <v-form @submit.prevent="createTask" class="mb-3">
      <v-textarea
        v-model="newTask"
        :label="`${$t('projects.modal_titles.new_task')}...`"
        :disabled="loadingTasks"
        :loading="loadingTasks"
        class="mb-2"
        rows="1"
        auto-grow
        hide-details
        single-line
        @keydown.esc.stop="resetNewTask"
        @focus="areNewTaskActionsDisplayed = true"
        @keypress.enter="onEnterPress"
      />
      <div class="text-right">
        <v-btn v-if="areNewTaskActionsDisplayed" class="mr-2" text @click="resetNewTask">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn
          v-if="areNewTaskActionsDisplayed"
          :disabled="!newTask || isNewTaskRequestPending"
          :loading="isNewTaskRequestPending"
          color="primary"
          type="submit"
          text
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </div>
    </v-form>
    <draggable
      v-model="tasks"
      :disabled="$store.state.settings.isTouchDevice"
      group="tasks"
      @start="onDrag"
      @end="onDrop"
    >
      <div v-for="item in tasks" :key="item.id" class="task-row" @click="editTask(item)">
        <span :class="{ completed: item.status === 'done' }">
          {{ item.title }}
        </span>
        <v-tooltip v-if="item.description && !$store.state.settings.isTouchDevice" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-1" small> description </v-icon>
          </template>
          <pre style="font: inherit">{{ item.description }}</pre>
        </v-tooltip>
        <Attachments :attachments="item.attachments" />
        <v-spacer />

        <div class="text-right">
          <v-chip v-if="item.type" small label @click.stop>
            <template v-if="item.type === 'ux'">
              {{ item.type.slice(0, 2).toUpperCase() }}
            </template>
            <template v-else>
              {{ item.type.slice(0, 1).toUpperCase() }}
            </template>
          </v-chip>

          <v-chip
            :color="statusColors[item.status]"
            text-color="white"
            class="clickable"
            :disabled="!canEditTask(item)"
            style="width: 130px; justify-content: center"
            small
            label
            @click.stop="toggleTaskStatus(item)"
          >
            {{ $t(`projects.task_status.${item.status}`) }}
          </v-chip>
        </div>
      </div>
    </draggable>

    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      scrollable
      persistent
    >
      <ProjectTaskForm
        :key="editedTask.id || 0"
        :item="editedTask"
        :can-edit-task="canEditTask(editedTask)"
        @close="dialog = false"
        @update="updateTask"
        @delete="deleteTask"
      />
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Attachments from '../../components/ListAttachments.vue';
import ProjectTaskForm from '../../components/forms/ProjectTaskForm';
import { openSnackbar } from '@/util/event-bus';
import httpClient from '@/api/http-client';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ProjectTasks',

  components: {
    ProjectTaskForm,
    Attachments,
    draggable,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
    },

    userStory: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      taskApiRoute: 'api/project-tasks/',
      statusColors: {
        new: 'error',
        in_progress: 'info',
        ready_for_test: '#03a9f4',
        done: 'success',
      },

      dialog: false,
      newTask: '',
      isNewTaskRequestPending: false,
      areNewTaskActionsDisplayed: false,
      loadingTasks: false,
      editedTask: {},
      tasks: [],
      tasksBeforeReorder: [],
      updatingTaskIds: {},
    };
  },

  created() {
    this.getStoryTasks();
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),

    statusTypes() {
      return [
        { value: 'new', text: this.$t('projects.issue_status.new') },
        { value: 'seen', text: this.$t('projects.issue_status.seen') },
        { value: 'in_progress', text: this.$t('projects.issue_status.is_progress') },
        { value: 'ready_for_test', text: this.$t('projects.issue_status.ready_for_test') },
        { value: 'postponed', text: this.$t('projects.issue_status.postponed') },
        { value: 'closed', text: this.$t('projects.issue_status.closed') },
        { value: 'rejected', text: this.$t('projects.issue_status.rejected') },
      ];
    },
  },

  methods: {
    ...mapMutations('projectUserStories', [
      'UPDATE_PROJECT_USER_STORY_TASKS_COUNT',
      'UPDATE_PROJECT_USER_STORY_COMPLETED_TASKS',
    ]),

    getStoryTasks() {
      const params = {
        project_user_story_id: this.userStory.id,
      };
      this.loadingTasks = true;
      httpClient
        .get('api/project-tasks', { params })
        .then((res) => {
          this.tasks = res.data;
        })
        .finally(() => {
          this.loadingTasks = false;
        });
    },

    onDrag() {
      this.tasksBeforeReorder = [...this.tasks];
    },

    onDrop() {
      const requestBody = {
        task_ids: this.tasks.map((t) => t.id),
      };
      httpClient.post('api/project-tasks/reorder', requestBody).catch(() => {
        this.tasks = this.tasksBeforeReorder;
      });
    },

    createTask() {
      const payload = {
        title: this.newTask,
        status: 'new',
        project_user_story_id: this.userStory.id,
        project_id: this.projectId,
        is_private: false,
        order: this.tasks.length ? this.tasks[this.tasks.length - 1].order + 1 : 1,
      };

      this.isNewTaskRequestPending = true;
      httpClient
        .post('api/project-tasks', payload)
        .then((response) => {
          this.tasks.push(response.data);
          this.UPDATE_PROJECT_USER_STORY_TASKS_COUNT({
            sprintId: this.userStory.sprint_id,
            userStoryId: this.userStory.id,
          });
          this.newTask = '';
          openSnackbar(this.$t('projects.task_created'));
        })
        .finally(() => {
          this.isNewTaskRequestPending = false;
        });
    },

    updateTask(task) {
      this.tasks = this.tasks.map((t) => (t.id === task.id ? task : t));
      openSnackbar(this.$t('projects.task_updated'));
    },

    onEnterPress(evt) {
      if (evt.shiftKey || evt.ctrlKey) {
        return;
      }
      evt.preventDefault();
      this.createTask();
    },

    editTask(item) {
      this.editedTask = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    resetNewTask(evt) {
      evt.target.blur();
      this.areNewTaskActionsDisplayed = false;
      this.newTask = '';
    },

    deleteTask(task) {
      this.tasks = this.tasks.filter((t) => t.id !== task.id);
      openSnackbar(this.$t('projects.task_deleted'));
    },

    toggleTaskStatus(task) {
      if (this.updatingTaskIds[task.id]) {
        return;
      }
      const updatedTask = { ...task };
      if (updatedTask.status === 'new') {
        updatedTask.status = 'in_progress';
      } else if (task.status === 'in_progress') {
        updatedTask.status = 'ready_for_test';
      } else if (task.status === 'ready_for_test') {
        updatedTask.status = 'done';
      } else {
        updatedTask.status = 'new';
      }
      this.$set(this.updatingTaskIds, task.id, true);
      httpClient
        .put(`${this.taskApiRoute}${task.id}`, updatedTask)
        .then((response) => {
          this.tasks = this.tasks.map((t) => (t.id === response.data?.id ? response.data : t));
          this.UPDATE_PROJECT_USER_STORY_COMPLETED_TASKS({
            sprintId: this.userStory.sprint_id,
            userStoryId: this.userStory.id,
            newStatus: updatedTask.status,
          });
        })
        .finally(() => {
          this.$delete(this.updatingTaskIds, task.id);
        });
    },

    canEditTask(task) {
      if (
        (this.currentUserPermissionInSelectedProject === 'supplier' ||
          this.currentUserPermissionInSelectedProject === 'employee') &&
        task.created_by !== this.currentUser.id
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.task-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  min-height: 40px;
}

.theme--light .task-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.theme--light .task-row:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.theme--light .task-row:hover {
  background: #eee;
}

.theme--dark .task-row {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
}
.theme--dark .task-row:first-child {
  border-top: 1px solid hsla(0, 0%, 100%, 0.12);
}
.theme--dark .task-row:hover {
  background: rgba(255, 255, 255, 0.08);
}

.v-chip {
  margin: 0 2px 4px 2px;
  width: 36px;
  justify-content: center;
}
</style>
